import React, {
    createContext, ReactNode, useState, useMemo,
} from 'react';
import { EstimateType } from '../../types/EstimateType';
import { ColumnVO, SortModelItem } from 'ag-grid-community';

interface Props {
    children: ReactNode
}

export interface EstimateTypeExpanded extends EstimateType {
    isExpanded: boolean;
}

export interface LevelState {
    values?: EstimateTypeExpanded[];
    expanded?: boolean;
    total?: number;
}

export interface LevelsRef {
    [key: string]: LevelState;
}

interface UnitRateContextType {
  unitRateId: string | null;
  setUnitRateId: (unitRateId: string | null) => void;
  levelsData: LevelsRef;
  setLevelsData: (levelsData: LevelsRef) => void;
  rowData: ColumnVO[];
  setRowData: (rowData: ColumnVO[]) => void;
  valueColsData: ColumnVO[];
  setValueColsData: (valueColsData: ColumnVO[]) => void;
  sortModel :SortModelItem[];
  setSortModel: (sortModel: SortModelItem[]) => void;
  filterModel: string | null;
  setFilterModel: (filterModel: string | null) => void;
}

const UnitRateContext = createContext<UnitRateContextType>({
    unitRateId: null,
    setUnitRateId: () => {},
    levelsData: {},
    setLevelsData: () => {},
    rowData: [],
    setRowData: () => [],
    valueColsData: [],
    setValueColsData: () => [],
    sortModel: [],
    setSortModel: () => [],
    filterModel: null,
    setFilterModel: () => {},
});

function UnitRateContextProvider(props: Props) {
    const { children } = props;
    const [unitRateId, setUnitRateId] = useState<string | null>(null);
    const [levelsData, setLevelsData] = useState<LevelsRef>({});
    const [rowData, setRowData] = useState<ColumnVO[]>([]);
    const [valueColsData, setValueColsData] = useState<ColumnVO[]>([]);
    const [sortModel, setSortModel] = useState<SortModelItem[]>([]);
    const [filterModel, setFilterModel] = useState<string | null>(null);

    const defaultValue = useMemo(() => ({
        unitRateId,
        setUnitRateId,
        levelsData,
        setLevelsData,
        rowData,
        setRowData,
        valueColsData,
        setValueColsData,
        sortModel,
        setSortModel,
        filterModel,
        setFilterModel,
    }), [unitRateId, levelsData, rowData, valueColsData, sortModel, filterModel]);

    return (
        <UnitRateContext.Provider value={defaultValue}>
            {children}
        </UnitRateContext.Provider>
    );
}

export { UnitRateContext };

export default UnitRateContextProvider;
