import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../../store/context/userContext';
import EditGridLayoutStyles from './EditGridLayout.style';

interface CurrentGridLayout {
    id: string;
    org_id: string;
    user_id: string;
    project_id: string;
    grid_layout_id: string;
    created_at: string;
    updated_at: string;
}
interface EstimateGridLayoutType {
    id?: string;
    pivot_mode?: boolean;
    grid_state?: string;
    layout_name?: string;
    org_id: string;
    created_at: string;
    updated_at: string;
    current_grid_layouts?: CurrentGridLayout[];
}

interface GetEstimateGridLayoutType {
    getEstimateGridLayout?: {
      data?: EstimateGridLayoutType[];
    }
}
interface Props {
    editPopup: boolean,
    setEditPopup: React.Dispatch<React.SetStateAction<boolean>>;
    editLayoutDetails?: EstimateGridLayoutType | null;
    editLayoutMutate: (params: any) => void;
    gridLayoutData?: GetEstimateGridLayoutType,
    handleCancel?: (p: boolean) => void;
    setEditLayoutName: React.Dispatch<React.SetStateAction<string | undefined>>;
    editlayoutName?: string
    isLayoutNameDuplicate?: boolean;
    setIsLayoutNameDuplicate: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditGridLayoutModal(props: Props) {
    const classes = EditGridLayoutStyles();
    const {
        editPopup, setEditPopup, editLayoutDetails, editLayoutMutate, gridLayoutData, handleCancel, setEditLayoutName, editlayoutName,
        isLayoutNameDuplicate, setIsLayoutNameDuplicate,
    } = props;
    const userCtx = useContext(UserContext);

    const isUpdated = editLayoutDetails?.layout_name
        ? editlayoutName?.trim() !== editLayoutDetails.layout_name.trim()
        : false;

    useEffect(() => {
        if (editLayoutDetails?.layout_name) {
            setEditLayoutName(editLayoutDetails?.layout_name);
        }
    }, [editLayoutDetails]);

    const handleSave = () => {
        const filteredLayouts = gridLayoutData && gridLayoutData?.getEstimateGridLayout?.data && gridLayoutData?.getEstimateGridLayout?.data.filter(
            (item: EstimateGridLayoutType) => item?.layout_name?.trim().toLowerCase() === editlayoutName?.trim().toLowerCase(),
        );
        if (filteredLayouts && filteredLayouts?.length > 0) {
            setIsLayoutNameDuplicate(true);
            return;
        }
        setEditPopup?.(false);
        editLayoutMutate({
            org_id: userCtx?.user?.default_org_id || '',
            newLayoutName: editlayoutName,
            editLayoutId: editLayoutDetails?.id,

        });
    };

    const handleEditLayoutNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditLayoutName(event.target.value);
        setIsLayoutNameDuplicate(false);
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={editPopup}>
                <DialogTitle className={classes.dilogBoxSection}>Edit Grid Layout</DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Grid container spacing={2} className={classes.sectionBody}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" className={classes.labelText}>
                                Name
                            </Typography>
                            <TextField
                                fullWidth
                                color="secondary"
                                placeholder="Layout"
                                value={editlayoutName}
                                error={isLayoutNameDuplicate}
                                helperText={isLayoutNameDuplicate && 'Layout name already exists.'}
                                onChange={handleEditLayoutNameChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.outlineButton}>
                        <Button variant="outlined" onClick={() => handleCancel?.(false)}>Cancel</Button>
                    </Box>
                    <Box className={classes.filledButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!editlayoutName?.trim().length || !isUpdated}
                            onClick={handleSave}
                        >
                            Update
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default EditGridLayoutModal;
