import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validations';

const SAVE_GRID_LAYOUT = gql`
    mutation ($module_name: String!, $grid_state: String!){
      saveGrid (
        module_name: $module_name
        grid_state: $grid_state
      ){
        message
      }  
}`;

const GET_GRID_LAYOUT = (module_name: string) => {
    const moduleName = module_name ? `module_name: ${addEscapeCharacters(module_name)}` : 'module_name: ""';
    return gql`
    query{
      getGridLayout(
        ${moduleName}
      ) {
        data {
          grid_state
          created_at
          updated_at
          module_name
        }
      }
    }`;
};

const RESET_GRID_LAYOUT = gql`
  mutation ResetGridLayout($module_name: String!) {
    resetGrid(module_name: $module_name) {
      message
    }
  }
`;

const SAVE_ESTIMATE_GRID_LAYOUT = gql`
    mutation (
    $module_name: String!, $grid_state: String!, $layout_name: String!,
    $pivot_mode: Boolean!, $project_id: String!, $org_id: String!
    ){
      estimateSaveGrid (
        module_name: $module_name
        grid_state: $grid_state
        pivot_mode: $pivot_mode
        layout_name: $layout_name
        org_id: $org_id
        project_id: $project_id
      ){
        message
      }  
}`;

const RESET_ESTIMATE_GRID_LAYOUT = gql`
    mutation ($grid_state: String!, $layout_name: String!, $project_id: String!, $org_id: String!){
      resetEstimateGrid (
        grid_state: $grid_state
        layout_name: $layout_name
        org_id: $org_id
        project_id: $project_id
      ){
        message
      }  
}`;

const RESET_MULTIPLE_ESTIMATE_GRID_LAYOUT = gql`
  mutation ResetMultipleEstimateGrids($grid_layout_id: String!, $user_id: String!, $org_id: String!, $project_id: String!) {
    resetMultipleEstimateGrids(
      gridlayout_id: $grid_layout_id,
      user_id: $user_id,
      org_id: $org_id,
      project_id: $project_id
    ) {
      message
    }
  }
`;

const GET_GRID_ESTIMATE_LAYOUT = (org_id: string) => gql`
query{
  getEstimateGridLayout(
    org_id: ${addEscapeCharacters(org_id)}
  ) {
    data {
      id
      grid_state
      pivot_mode
      created_at
      updated_at
      layout_name
      current_grid_layouts {
        id
        org_id
        user_id
        project_id
        created_at
      }
    }
  }
}`;
const DEFAULT_VIEW_ESTIMATE_GRID_LAYOUT = gql`
  mutation resetDefaultViewGridLayout($project_id: String!, $org_id: String!) {
    resetDefaultViewGrid(project_id: $project_id, org_id: $org_id) {
      message
    }
  }
`;

const EDIT_ESTIMATE_GRID_LAYOUT_NAME = gql`
  mutation updateEstimateGridLayout($editLayoutId: String!, $org_id: String!, $newLayoutName: String!) {
    updateEstimateGrid(editLayoutId: $editLayoutId, org_id: $org_id, newLayoutName: $newLayoutName) {
      message
    }
  }
`;

const DELETE_ESTIMATE_GRID_LAYOUT = gql`
  mutation deleteEstimateGridLayout($layout_name: String!, $project_id: String!, $org_id: String!, $deleteLayoutId: String!) {
    deleteEstimateGrid(layout_name: $layout_name, project_id: $project_id, org_id: $org_id, deleteLayoutId: $deleteLayoutId) {
      message
    }
  }
`;

export default {
    SAVE_GRID_LAYOUT,
    GET_GRID_LAYOUT,
    RESET_GRID_LAYOUT,
    GET_GRID_ESTIMATE_LAYOUT,
    SAVE_ESTIMATE_GRID_LAYOUT,
    DELETE_ESTIMATE_GRID_LAYOUT,
    RESET_ESTIMATE_GRID_LAYOUT,
    RESET_MULTIPLE_ESTIMATE_GRID_LAYOUT,
    DEFAULT_VIEW_ESTIMATE_GRID_LAYOUT,
    EDIT_ESTIMATE_GRID_LAYOUT_NAME,
};
