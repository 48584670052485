/* eslint-disable no-nested-ternary */
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    Box, Button, FormControl, ListItemIcon, ListItemText, MenuItem, Tooltip, Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { PATH_CMFR } from '../../../../Routes/path';
import CostHeaderBar from '../../../../components/costHeaderBar';
import QuickSearchFilter from '../../../../components/quickSearchFilter/QuickSearchFilter';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import { ColumnState } from 'ag-grid-community';
import { ROLES } from '../../../../constants';
import exportExcel from '../../../../helper/ExportExcel';
import { useUserRole } from '../../../../hooks/useUserRole';
import { ProjectContext } from '../../../../store/context/projectContext';
import { UserContext } from '../../../../store/context/userContext';
import { OutletContext } from '../../../../types/OrganisationDetailType';
import DeleteRowModel from '../../../ProjectsListing/Components/DeleteRowModel';
import TableHeaderStyle from './TableHeader.style';
import { Edit, EditCalendarOutlined } from '@mui/icons-material';

// interface Props {
// tittle: string,
// handleClickOpen: () => void;
// handleChange: (event: SelectChangeEvent) => void;
// handleClickOpenDuplicate: () => void;
// }

interface CurrentGridLayout {
    id: string;
    org_id: string;
    user_id: string;
    project_id: string;
    grid_layout_id: string;
    created_at: string;
    updated_at: string;
}
interface EstimateGridLayoutType {
    id?: string;
    grid_state?: string;
    layout_name?: string;
    pivot_mode?: boolean;
    org_id: string;
    created_at: string;
    updated_at: string;
    current_grid_layouts?: CurrentGridLayout[];
  }
  interface MutateEstimateGridLayoutType {
        grid_layout_id: string;
        user_id: string;
        org_id: string;
        project_id: string;
}

interface ResetDefaultViewEstimateMutateType {
    org_id: string;
    project_id: string;
}
  interface GetEstimateGridLayoutType {
    getEstimateGridLayout?: {
    data?: EstimateGridLayoutType[];
    }
  }

interface MyComponentProps {
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    searchText: string,
    gridLayoutData?: GetEstimateGridLayoutType,
    setGridView: React.Dispatch<React.SetStateAction<any>>;
    gridView?: string,
    deleteMutate: (params: { layout_name: string, org_id: string, project_id: string, deleteLayoutId: string }) => void,
    resetMultipleEstimateMutate: (params: MutateEstimateGridLayoutType) => void;
    isResetMultipleEstimateLoading?: boolean,
    canRenderClientSide?: boolean,
    resetDefaultViewEstimateMutate: (params: ResetDefaultViewEstimateMutateType) => void;
    handleEditLayout: (event: React.MouseEvent, params: EstimateGridLayoutType) => void;
}

function EstimateHeader(props: MyComponentProps) {
    const classes = TableHeaderStyle();
    const [openDelettePopup, setOpenDelettePopup] = useState(false);
    const loggedInUserRole = useUserRole();
    const userRole = ROLES.find((role) => role.id === loggedInUserRole);

    const {
        searchText, setSearchText, gridLayoutData, setGridView,
        gridView, deleteMutate, resetMultipleEstimateMutate, isResetMultipleEstimateLoading, canRenderClientSide, resetDefaultViewEstimateMutate,
        handleEditLayout,
    } = props;
    const navigate = useNavigate();
    const selectMenuOptions = ['Default View'];
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);
    const isViewOnly = Number(projectCtx?.project?.permission_id) === 1;
    const cont: OutletContext = useOutletContext();
    const [deleteLayoutDetails, setDeleteLayoutDetails] = useState<EstimateGridLayoutType | null>(null);

    const handleChange = (event: SelectChangeEvent) => {
        const selectedLayout = gridLayoutData?.getEstimateGridLayout?.data?.find(
            (layout) => layout.layout_name === event.target.value,
        );
        if (event.target.value === 'Default View') {
            resetDefaultViewEstimateMutate({
                org_id: userCtx?.user?.default_org_id || '',
                project_id: projectCtx?.project?.id || '',
            });
        }
        if (selectedLayout) {
            resetMultipleEstimateMutate({
                grid_layout_id: selectedLayout.id as string,
                user_id: userCtx?.user?.user_id as string,
                org_id: userCtx?.user?.default_org_id as string,
                project_id: projectCtx?.project?.id as string,
            });
        }
        setGridView(event?.target?.value);
    };

    const navigateToReports = () => {
        navigate(`${PATH_CMFR.capex.reports}?module=estimate`);
    };
    const handleExportExcel = () => {
        exportExcel({
            activeOrgId: userCtx?.user?.default_org_id,
            grid: 'estimate_detail',
            activeVersionId: projectCtx?.project?.version_id,
        }).catch((err) => {
            const errorMessage = err instanceof Error ? err.message : 'An error occurred while generating the report';
            cont.showNotificationBar(errorMessage, 'error');
        });
    };

    const handleReset = (event: React.MouseEvent, pr: EstimateGridLayoutType) => {
        event.stopPropagation();
        setDeleteLayoutDetails(pr);
        setOpenDelettePopup(true);
    };

    const handleDeleteLayout = () => {
        deleteMutate({
            deleteLayoutId: deleteLayoutDetails?.id || '',
            layout_name: deleteLayoutDetails?.layout_name || '',
            org_id: userCtx?.user?.default_org_id || '',
            project_id: projectCtx?.project?.id || '',
        });
        const currentLayout = deleteLayoutDetails?.current_grid_layouts?.some(
            (grid) => grid.user_id === userCtx?.user?.user_id
                && grid.project_id === projectCtx?.project?.id
                && grid.org_id === userCtx?.user?.default_org_id,
        );
        if (currentLayout) {
            setGridView('Default View');
        }
        // setLayoutName?.('');
        setOpenDelettePopup(false);
    };

    return (
        <div className={classes.TableHeader}>
            <Box className={classes.inTableHeader}>
                <Typography variant="h2">
                    ESTIMATE
                </Typography>
                { canRenderClientSide && (
                    <Tooltip title="Client side">
                        <CopyrightOutlinedIcon className={classes.clientSideIcon} />
                    </Tooltip>
                )}
                <CostHeaderBar />
                <Box className={classes.versonUndo}>
                    {/* <Box className={classes.undoButtn}>
                        <button type="submit">
                            <BarChartOutlinedIcon />
                            {' '}
                            Benchmark
                        </button>
                    </Box> */}
                    <Box className={classes.outlineButton}>
                        <Button onClick={navigateToReports}>
                            <DataSaverOffOutlinedIcon />
                            {' '}
                            Reports
                        </Button>
                    </Box>
                    {/* <SelectDropdown view={view} menuOptions={selectMenuOptions} setView={setView} /> */}
                    <FormControl variant="filled">
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="View"
                            value={isResetMultipleEstimateLoading ? 'Updating...' : gridView || 'Default View'}
                            onChange={handleChange}
                            IconComponent={KeyboardArrowDownIcon}
                            // renderValue={(value) => value}
                            renderValue={(value) => (
                                <Tooltip title={value} placement="top" arrow>
                                    <span className={classes.selectedDropDown}>
                                        {value}
                                    </span>
                                </Tooltip>
                            )}
                            disabled={isResetMultipleEstimateLoading || canRenderClientSide}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxHeight: 300,
                                        width: 270,
                                        '& .MuiMenuItem-root': {
                                            color: '#787878',
                                        },
                                        '& .Mui-selected': {
                                            color: '#021948',
                                            bgcolor: '#E6ECFA !important',
                                        },
                                    },
                                },
                            }}
                        >
                            <MenuItem key="default" value="Default View">
                                Default View
                            </MenuItem>
                            {userRole?.role === 'Admin' ? (
                                gridLayoutData && gridLayoutData.getEstimateGridLayout && gridLayoutData.getEstimateGridLayout.data ? (
                                    gridLayoutData.getEstimateGridLayout.data.map((item: EstimateGridLayoutType) => (
                                        <MenuItem key={item.id} value={item?.layout_name}>
                                            <ListItemText className={classes.selectText} primary={item?.layout_name} />
                                            <ListItemIcon className={classes.deleteIcon}>
                                                <DeleteOutlineIcon onClick={(event) => handleReset(event, item)} />
                                            </ListItemIcon>
                                            <ListItemIcon className={classes.editIcon}>
                                                <Edit onClick={(event) => handleEditLayout(event, item)} />
                                            </ListItemIcon>
                                        </MenuItem>
                                    ))
                                ) : null
                            ) : (
                                gridLayoutData?.getEstimateGridLayout?.data ? (
                                    gridLayoutData.getEstimateGridLayout.data.map((item: EstimateGridLayoutType) => (
                                        <MenuItem key={item.id} value={item?.layout_name}>
                                            <ListItemText className={classes.selectText} primary={item?.layout_name} />
                                        </MenuItem>
                                    ))
                                ) : null
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <QuickSearchFilter setSearchText={setSearchText} searchText={searchText} />
            </Box>
            {/* <div className="example-header">
                <span>Quick Filter:</span>
                <input
                    type="text"
                    id="filter-text-box"
                    placeholder="Filter..."
                    onChange={onFilterTextBoxChanged}
                />
            </div> */}
            <Box className={classes.versonUndo}>
                {/* <Box className={`${classes.undoButtn} ${classes.deleteButtonStyle}`}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <DeleteOutlineOutlinedIcon />
                        {' '}
                        Delete
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <EditOutlinedIcon />
                        {' '}
                        Edit
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit" onClick={handleClickOpenDuplicate}>
                        <ContentCopyIcon />
                        {' '}
                        Duplicate
                    </button>
                </Box>
                <Box className={classes.undoButtn}>
                    <button type="submit">
                        <CloudDownloadOutlinedIcon />
                        {' '}
                        Import
                    </button>
                </Box> */}
                {!isViewOnly && (
                    <Box className={classes.outlineButton}>
                        <Button onClick={handleExportExcel}>
                            <CloudUploadOutlinedIcon />
                            {' '}
                            Export
                        </Button>
                    </Box>
                )}
                {/* <Box className={classes.undoButtnNew}>
                    <button type="submit" onClick={handleClickOpen}>
                        <AddIcon />
                        {' '}
                        New
                    </button>
                </Box> */}
            </Box>
            {openDelettePopup && (
                <DeleteRowModel
                    open={openDelettePopup}
                    setOpenDelRow={setOpenDelettePopup}
                    deleteAction={handleDeleteLayout}
                    orgName={deleteLayoutDetails?.layout_name}
                    title="Grid Layout"
                />
            )}
        </div>
    );
}

export default EstimateHeader;
