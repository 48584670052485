import { makeStyles } from '@mui/styles';

const ProjectViewStyle = makeStyles(() => ({
    TableHeader: {
        margin: '0px',
        color: '#021948',
        padding: '5px 0px 20px 0px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& h6': {
            fontSize: '22px',
            fontWeight: '700',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            '& span': {
                fontSize: '11px',
                fontWeight: '600',
            },
        },
        '& .donutchart-innertext': {
            visibility: 'hidden',
        },
        '& .donutchart-arcs': {
            '& path': {
                stroke: 'none',
                opacity: '1',
            },
        },
    },
    inTableHeader: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: '0px',
        },
        '& h2': {
            fontWeight: '700',
            color: '#1c1c1c !important',
        },
        '& input': {
            color: '#A5A5A5',
            fontSize: '12px',
            border: '1px solid #787878',
            padding: '7px 10px 7px 30px',
            width: '143px',
            borderRadius: '4px',
            letterSpacing: '.5px',
            fontWeight: '500',
        },
    },
    filledBtn: {
        backgroundColor: '#007DFF !important',
        border: '1px solid #007DFF !important',
    },
    chartStyle: {
        marginLeft: '25px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.15)',
        borderRadius: '10px',
        padding: '2px 12px',
        '& svg': {
            verticalAlign: 'text-bottom',
        },
        '& span': {
            color: '#353535',
            '&:nth-child(1)': {
                color: '#1C1C1C',
            },
            verticalAlign: 'middle',
        },
    },
    versonUndo: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiSelect-select': {
            padding: '6px',
            fontSize: '18px',
            height: '21px !important',
            borderRadius: '5px !important',
            border: '1px solid #787878',
            backgroundColor: '#ffffff !important',
            alignItems: 'center',
            display: 'flex',
        },
        '& .MuiFormControl-root': {
            marginLeft: '15px',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            width: '135px',
            borderRadius: '8px',
            '&:before': {
                border: 'none !important',
            },
            '&:after': {
                border: 'none',
            },
            '&:hover': {
                border: 'none',
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
            },
        },
        '& .MuiInputBase-input': {
            padding: '4px 32px 4px 12px !important',
            fontSize: '12px',
            color: 'rgba(0, 0, 0, 0.57)',
            fontFamily: 'Inter-Medium',
        },
        '& svg': {
            color: '#787878',
        },
    },
    outlineButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#787878',
            border: '1px solid #787878',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '4px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#787878',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '4px',
            },
        },
    },
    deleteButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#B00000',
            border: '1px solid #B00000',
            borderRadius: '4px',
            backgroundColor: 'transparent',
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '& svg': {
                color: '#B00000',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '2px',
            },
        },
    },
    filledButton: {
        marginLeft: '15px',
        '& button': {
            fontSize: '12px',
            color: '#fff',
            border: '1px solid #007DFF',
            borderRadius: '4px',
            backgroundColor: '#007DFF',
            boxShadow: `0px 2.85714px 4.7619px -1.90476px rgba(0, 0, 0, 0.2), 0px 1.90476px 2.85714px rgba(0, 0, 0, 0.14),
             0px 0.952381px 4.7619px rgba(0, 0, 0, 0.12)`,
            padding: '3px 11px',
            cursor: 'pointer',
            textTransform: 'unset',
            fontFamily: 'Inter-Medium',
            '&:hover': {
                backgroundColor: '#007DFF !important',
            },
            '& svg': {
                color: '#fff',
                fontSize: '15px',
                verticalAlign: 'sub',
                marginRight: '5px',
            },
        },
    },
    opacityBlur: {
        opacity: '50%',
        '& .ag-cell .ag-icon': {
            display: 'inline !important',
        },
    },
    filterDesc: {
        marginBottom: '5px',
        '& span': {
            '&:nth-child(1)': {
                fontSize: '14px',
                fontFamily: 'Inter',
            },
            '&:nth-child(2)': {
                fontSize: '14px',
                fontFamily: 'Inter-Medium',
                color: '#000',
                fontWeight: 600,
            },
        },
        '& button': {
            fontSize: '14px',
            textDecoration: 'underline',
            color: '#007DFF',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
        },
    },
    projectListMain: {
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 264px) !important',
            },
        },
        '& .ag-right-aligned-cell': {
            // textAlign: 'unset',
        },
    },
    projectListing: {
        '& .ag-theme-alpine': {
            '&:nth-child(1)': {
                height: 'calc(100vh - 243px) !important',
            },
        },
        '& .ag-right-aligned-cell': {
            // textAlign: 'unset',
        },
    },
}));

export default ProjectViewStyle;
