import React, {
    useEffect, useState, useContext,
} from 'react';
import { Box } from '@mui/material';
import { ColumnDefs } from '../../../../types/AgGridTypes';
import AgGridComponent from '../../../../components/agGridTableComponent';
import wbsQueries from '../../../../queries/wbs';
import { useGQLQuery } from '../../../../hooks/useGQLQuery';
import Loader from '../../../../components/Loader';
import { DEFAULT_PAGE_SIZE } from '../../../../constants';
import Pagination from '../../../../components/paginationTable';
import { UserContext } from '../../../../store/context/userContext';
import { ProjectContext } from '../../../../store/context/projectContext';
import { useOutletContext } from 'react-router-dom';
import { OutletContext, QueryErrorResponse } from '../../../../types/OrganisationDetailType';
import WbsCustom from './WbsCustom';
import { JSONParse } from '../../../../helper/StorageHelper';
import { truthyCodeValidation } from '../../../../helper/validations';
import { ValueGetterParams } from 'ag-grid-community';

interface VersionDefaultRenderer {
    labour_hoursTotal: number,
    labourTotal: number,
    distributableTotal: number,
    c_equipmentTotal: number,
    materialTotal: number,
    sustaining_total: number,
    equipmentTotal: number,
    subcontractTotal: number,
    indirectTotal: number,
    total_costTotal: number,
    name: string,
    total_cost: string | number,
}

type VersionObject = {
    description: string;
    [key: string]: string | number;
};

interface Props {
    view?: string,
    searchText?: string,
    setSearchText?: React.Dispatch<React.SetStateAction<string>>;
    hideUnusedFlag: boolean;
    setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;

}

interface WbsColumnDefs extends ColumnDefs {
    sort?: string,
    pinned?: string,
    valueGetter?: (params: ValueGetterParams<{ [key: string]: number | string }>) => string | number;
}

export default function WBSVersionViews(props:Props) {
    const {
        view, searchText, setSearchText, hideUnusedFlag, setIsFetching,
    } = props;
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [newColumnDefs, setNewColumnDefs] = useState<ColumnDefs[]>([]);
    const [colHeader, setColHeader] = useState<string[]>([]);
    const cont: OutletContext = useOutletContext();
    const userCtx = useContext(UserContext);
    const projectCtx = useContext(ProjectContext);

    const displayedCurrencyId = projectCtx?.project?.currency_id;
    const displayCurr = projectCtx?.projectCurrencyData?.getprojectCurrency?.find((curr) => curr.id === displayedCurrencyId);
    const exchangeRate = displayCurr?.exchange_rate || 1;

    const handleApiError = ({ response }: QueryErrorResponse) => {
        const message = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        cont.showNotificationBar(message, 'error');
    };

    const createVersionData = (params: VersionDefaultRenderer[]) => {
        const result: VersionObject = {
            description: 'TOTAL',
        };
        for (let i = 0; i < params.length; i += 1) {
            result[`column_${params[i].name}`] = params[i].total_cost && (+(params[i].total_cost) / exchangeRate).toFixed(0);
        }
        return [result];
    };

    const projectId = projectCtx?.project?.id || '';

    const { data: GetVersionData, isFetching, refetch } = useGQLQuery(
        `GetWbsVersion-${projectId || 0}`,
        wbsQueries.GET_WBS_VERSION(
            pageSkipValue,
            DEFAULT_PAGE_SIZE,
            userCtx?.user?.default_org_id || '',
            projectCtx?.project?.id || '',
            projectCtx?.project?.version_id || '',
            hideUnusedFlag,
        ),
        {},
        { onError: handleApiError },
    );
    // For WBS Version view...
    const versionViewData = GetVersionData?.wbsversion;
    const tableVersionData = versionViewData?.data;
    const tableVersionViewData: VersionObject[] = tableVersionData && tableVersionData !== undefined ? JSONParse(tableVersionData.toString()) : [];
    const versionPageInfo = versionViewData?.pageInfo;
    const versionTotalValue: VersionDefaultRenderer[] = versionViewData?.total_values && versionViewData?.total_values !== undefined
        ? JSONParse(versionViewData?.total_values.toString()) : [];
    // For Bottom Total Value..
    let pinnedBottomRowData;
    if (versionTotalValue && versionTotalValue.length > 0) {
        pinnedBottomRowData = createVersionData(versionTotalValue);
    }

    useEffect(() => {
        refetch();
    }, [pageSkipValue, hideUnusedFlag]);

    const columnDefs: WbsColumnDefs[] = [
        {
            field: 'code',
            headerName: 'WBS',
            initialWidth: 130,
            cellRenderer: WbsCustom,
            type: 'string',
            sort: 'asc',
        },
        {
            field: 'description',
            headerName: 'Title',
            cellRenderer: WbsCustom,
            initialHide: view !== 'Version View',
            initialWidth: 400,
            type: 'string',
            enableValue: false,
        },
    ];

    const setVersionViewFunc = () => {
        if (tableVersionViewData && tableVersionViewData.length > 0 && view === 'Version View') {
            setColHeader([]);
            const tempArr: string[] = [];
            const colArr: WbsColumnDefs[] = [];
            Object.keys(tableVersionViewData[0]).forEach((item) => {
                if (item.startsWith('column_')) {
                    const ak = item.split('_')[1].replaceAll('_', ' ');
                    colArr.push(
                        {
                            field: item,
                            headerName: ak,
                            cellRenderer: WbsCustom,
                            // sorting: true,
                            initialHide: view !== 'Version View',
                            initialWidth: 150,
                            type: 'numericColumn',
                            enableValue: true,
                            valueGetter:
                            (params: ValueGetterParams<{ [key: string]: number | string }>) => (params.data ? (params.data[item]) || '' : ''),
                        },
                    );
                }
            });
            colArr.sort((a, b) => {
                if (a.headerName < b.headerName) return -1;
                if (a.headerName > b.headerName) return 1;
                return 0;
            });
            colArr.forEach((col) => {
                columnDefs.push(col);
                tempArr.push(`${col?.field}`);
                setNewColumnDefs(columnDefs);
            });
            setColHeader([...colHeader, ...tempArr]);
        }
    };

    const generateColumnDefs = () => {
        ['code1', 'code2', 'code3', 'code4', 'code5', 'code6'].forEach((code, index) => {
            const flag = (tableVersionViewData?.map((item) => ((item)[code])))?.some(truthyCodeValidation);
            if (flag) {
                columnDefs.push({
                    field: code,
                    headerName: `Code${index + 1}`,
                    initialWidth: 130,
                    cellRenderer: WbsCustom,
                    type: 'string',
                    initialHide: flag,
                    enableValue: false,
                });
            }
        });
        setNewColumnDefs(columnDefs);
    };

    useEffect(() => {
        setNewColumnDefs(columnDefs);
    }, [view]);

    useEffect(() => {
        setVersionViewFunc();
        generateColumnDefs();
    }, [view, isFetching]);

    useEffect(() => {
        setIsFetching(isFetching);
    }, [isFetching]);

    const handlePageChange = (page: number) => {
        // Fetch new page data
        const skipValue = (page - 1) * DEFAULT_PAGE_SIZE;
        setPageSkipValue(skipValue);
    };

    const totalPages = (versionPageInfo && versionPageInfo.totalcount) ? Math.ceil(versionPageInfo.totalcount / DEFAULT_PAGE_SIZE) : 0;

    return (
        <div>
            {/* Page Header Start */}
            <Loader loading={isFetching} />
            <Box>
                <AgGridComponent
                    columnDefs={newColumnDefs}
                    rowData={tableVersionViewData}
                    changeSortingValue={() => {}}
                    pinnedBottomRowData={pinnedBottomRowData}
                    WbsRowBgStyle
                    isClipboardEnabled
                    isExportEnabled
                    isRangeSelectable
                    isToolPanelsEnabled
                    isStatusBarEnabled
                    isGroupable
                    moduleName="wbs_versionview"
                    colFormat={colHeader}
                    quickFilterText={searchText}
                    setSearchText={setSearchText}
                />
            </Box>
        </div>
    );
}
